<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: ["id", "text"],
  data() {
    return {
      formData: {
        treshold_dari: "",
        treshold_sampai: "",
      },
    };
  },
  mounted() {
    this.getDataById();
  },
  methods: {
    submitForm() {
      const authToken = localStorage.access_token;
      axios
        .put(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/treshold-nilai/" +
            this.id +
            "/" +
            this.text,
          this.formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("Response:", response.data);
          this.resetForm();
          window.location.reload();
        })
        .catch((error) => {
          // Handle errors
          console.error("Error:", error.response.data);
        });
    },
    getDataById() {
      let url =
        process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/treshold-nilai/" +
        this.id +
        "/" +
        this.text;

      let self = this;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            var data = response_data_fix;
            self.formData.treshold_dari = data.treshold_dari;
            self.formData.treshold_sampai = data.treshold_sampai;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    resetForm() {
      this.formData.treshold_dari = "";
      this.formData.treshold_sampai = "";
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here if needed */
</style>

<template>
  <div class="container">
    <form @submit.prevent="submitForm" class="needs-validation" novalidate>
      <div class="mb-3">
        <label for="treshold_dari" class="form-label">Treshold Dari</label>
        <input
          v-model="formData.treshold_dari"
          class="form-control"
          id="treshold_dari"
          type="text"
          inputmode="decimal"
          pattern="[0-9]*[.,]?[0-9]+"
          step="0.01"
          required
        />
      </div>
      <div class="mb-3">
        <label for="treshold_sampai" class="form-label">Treshold Sampai</label>
        <input
          v-model="formData.treshold_sampai"
          class="form-control"
          id="treshold_sampai"
          type="text"
          inputmode="decimal"
          pattern="[0-9]*[.,]?[0-9]+"
          step="0.01"
          required
        />
      </div>
      <div class="mb-3">
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</template>
